import React from 'react';
import "./App.css";
import "./ThemeStyles.css";

import {BrowserRouter, Routes, Route} from "react-router-dom";
import {HomePage} from "./pages/HomePage";
import {PrivacyPolicyPage} from "./pages/PrivacyPolicyPage";
import {SlidariRushPage} from "./pages/slidari-rush/SlidariRushPage";
import {GamesPage} from "./pages/GamesPage";
import {ManageProfilePage} from "./pages/ManageProfilePage";
import {LoginPage} from "./pages/LoginPage";
import {AboutPage} from "./pages/AboutPage";
import {MPushMessageProvider} from "./components/core/MPushMessageProvider";
import {ImpressumPage} from "./pages/ImpressumPage";
import {ContactPage} from "./pages/ContactPage";
import {CookiePage} from "./pages/CookiePage";
import {MScrollTopAuto} from "./components/core/MScrollTopAuto";

function App() {
    return <>
        <MPushMessageProvider>
            <BrowserRouter>
                <MScrollTopAuto/>
                <Routes>
                    <Route path="/" element={<HomePage/>}/>
                    <Route path="about/" element={<AboutPage/>}/>
                    <Route path="login/" element={<LoginPage/>}/>
                    <Route path="privacy-policy/" element={<PrivacyPolicyPage/>}/>
                    <Route path="impressum/" element={<ImpressumPage/>}/>
                    <Route path="manage-profile/" element={<ManageProfilePage/>}/>
                    <Route path="contact/" element={<ContactPage/>}/>
                    <Route path="cookies/" element={<CookiePage/>}/>
                    <Route path="games">
                        <Route path="slidari-rush" element={<SlidariRushPage/>}/>
                        <Route path="" element={<GamesPage/>}/>
                    </Route>
                    <Route path="*" element={<HomePage/>}/>
                </Routes>
            </BrowserRouter>
        </MPushMessageProvider>
    </>;
}

export default App;
