import React from "react";

import {MBasePage} from "./core/MBasePage";
import {MCookiePanel} from "../components/core/MCookiePanel";
import {MFormattedTextContent} from "../components/core/MFormattedTextContent";
import {usePushMessage} from "../components/core/MPushMessageProvider";

import {MStatusStyle} from "../components/core/MStatusStyle";

export function CookiePage() {
    const pushMessage = usePushMessage();

    function cookieSettingsChanged(cookiesAccepted: boolean) {
        pushMessage.push("Cookie Einstellungen erfolgreich geändert!", cookiesAccepted ? "Cookies akzeptiert" : "Cookies abgelehnt", MStatusStyle.Nice, 1500)
    }

    return <MBasePage pageTitle="Impressum" showCookies={false}>
        <MFormattedTextContent style={{margin: "auto", maxWidth: "600px"}}>
            <MCookiePanel forceDisplay={true}
                          renderAsOverlay={false}
                          settingsSet={cookieSettingsChanged}
                          renderCookiesStatusHints={true}/>
        </MFormattedTextContent>
    </MBasePage>;
}
