/**
 * Konvertierungsfunktionen
 */
export class MConvert {
    /**
     * Erzeugt eine Zeichenkette mit der Zeitdifferenz zu einem bestimmten Zeitpunkt in sinnvollen Intervallen.
     * @param {string} dateString Zeichenkette mit dem Zeitpunkt
     * @param {string} prefixText Prefix Text
     * @param labels Labels mit den Texten für die Einheit der Zeitdifferenz (z.B. Tage, Wochen unw.)
     */
    public static toTimeDifference(dateString: string, prefixText: string = "", labels = {
        yr: {singular: 'year', plural: 'years'},
        mo: {singular: 'month', plural: 'months'},
        d: {singular: 'day', plural: 'days'},
        h: {singular: 'hour', plural: 'hours'},
        min: {singular: 'minute', plural: 'minutes'},
        s: {singular: 'second', plural: 'seconds'}
    }): string {
        const date = new Date(dateString);
        const now = new Date();

        if (isNaN(date.getTime())) {
            return dateString;
        }

        const seconds = Math.floor((now.getTime() - date.getTime()) / 1000);

        const intervals = [
            {label: labels.yr, seconds: 31536000},
            {label: labels.mo, seconds: 2592000},
            {label: labels.d, seconds: 86400},
            {label: labels.h, seconds: 3600},
            {label: labels.min, seconds: 60},
            {label: labels.s, seconds: 1}
        ];

        for (const interval of intervals) {
            const count = Math.floor(seconds / interval.seconds);

            // Wenn die Differenz 50 Stunden oder mehr beträgt
            if (interval.label === labels.d && count < 50 / 24) {
                continue;
            }

            if (count > 0) {
                const label = count === 1 ? interval.label.singular : interval.label.plural;
                if (prefixText != null && prefixText !== "") {
                    return `${prefixText} ${count} ${label}`;
                }

                return `${count} ${label}`;
            }
        }

        return dateString;
    }
}