import {MBasePage} from "./core/MBasePage";
import "./HomePage.css";

import {SmoothFadedContent} from "../components/SmoothFadedContent";
import {GamePanel} from "../components/GamePanel";
import {MFlexPanel} from "../components/core/MFlexPanel";

export function HomePage() {
    const imageShare = "https://slidari-world.com/api.slidari/v1/assets/image/slidari-rush/"

    return <MBasePage pageTitle="Home">
        <SmoothFadedContent>
            <MFlexPanel>
                <div>
                    <h1 style={{marginBottom: "8px"}}><b>Die Jagd beginnt JETZT!</b></h1>
                    <span style={{fontSize: "16px", display: "block"}}>Begib dich gleich auf die spannende Jagt nach den Pace ...</span>
                    <div style={{marginTop: "8px"}}>
                        <GamePanel title={"Slidari Rush"}
                                   gamePageUrl={"games/slidari-rush"}
                                   keywords={["Endless Runner", "Action", "Adventure"]}
                                   screenshotUrls={[imageShare + "Screen-001.png", imageShare + "Screen-002.png", imageShare + "Screen-003.png", imageShare + "Screen-004.png", imageShare + "Screen-005.png"]}/>
                    </div>
                </div>
                <hr/>
            </MFlexPanel>
        </SmoothFadedContent>
    </MBasePage>;
}
