import {MBasePage} from "./core/MBasePage";

import {SmoothFadedContent} from "../components/SmoothFadedContent";
import {MFormattedTextContent} from "../components/core/MFormattedTextContent";

export function AboutPage() {
    const hrStyle = {marginTop: "22px", marginBottom: "64px"};

    return <MBasePage pageTitle="Über uns">
        <SmoothFadedContent>
            <MFormattedTextContent style={{margin: "auto", maxWidth: "800px"}}>
                <h1>Slidari World</h1>
                <p>

                    Schön, dass du hierher gefunden hast! Im Folgenden erfährst du alles über diese Web-App und das
                    Projekt, das dahintersteht.
                </p>
                <hr style={hrStyle}/>
                <h2>Über den Entwickler</h2>
                <p>
                    Hi, ich bin Joscha! Slidari World ist eines meiner vielen Hobbyprojekte im Bereich der
                    Spieleentwicklung, dass ich 2023 gestartet habe. Aus meiner Leidenschaft für Softwareentwicklung und
                    digital Art entstanden, verfolge ich hier ein Ziel: Spiele zu erschaffen, die Spaß machen – für mich
                    beim Entwickeln und für euch beim Spielen. Es ist ein Projekt aus Leidenschaft, das stetig wächst
                    und neue Ideen hervorbringt.
                </p>
                <hr style={hrStyle}/>
                <h2>Die Slidari Web-App</h2>
                <p>
                    Die Slidari World Web-App wird bald das zentrale Zuhause für alle Spiele im Slidari Universum
                    sein. Hier können Spieler ein Benutzerprofil anlegen, Spielstände verwalten und bleiben immer auf
                    dem Laufenden über Neuigkeiten aus der Slidari World. Egal, ob du schon Teil der Community bist oder
                    gerade erst startest – die App wird alles bieten, um dich in die Welt von Slidari zu ziehen.
                </p>
                <hr style={hrStyle}/>
                <h2>Das Volk der Slidari</h2>
                <p>
                    Die Slidar sind das Volk, um das sich in meinen Spielen alles dreht. Sie sind die Seele
                    der Geschichten und Abenteuer im Slidari Universum – und damit das Herzstück dieser Welt, die es zu
                    erkunden gilt.
                </p>
                <h2>Pace</h2>
                <p>
                    Pace ist die Währung im Slidari Universum.
                </p>
            </MFormattedTextContent>
        </SmoothFadedContent>
    </MBasePage>;
}
