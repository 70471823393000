import "./GamePanel.css";

import {MPanel} from "./core/MPanel";
import {MSlideshow} from "./core/MSlideshow";
import {MButton} from "./core/MButton";
import {useNavigate} from "react-router-dom";

import {Game} from "../types/Game";

export function GamePanel(game: Game) {
    const navigate = useNavigate();

    return <MPanel className="game-panel" noPadding={true}>
        <MSlideshow imageUrls={game.screenshotUrls}/>
        <div className="content">
            <h1>{game.title}</h1>
            <hr/>
            <div className="keywords">
                {game.keywords?.map((kw) => {
                    return <div key={game.keywords?.indexOf(kw)}>{kw}</div>
                })}
            </div>
            {game.gamePageUrl && <MButton onClick={() => {
                navigate(game.gamePageUrl)
            }} transparentStyle={true}>Mehr erfahren ...</MButton>}
        </div>
    </MPanel>;
}
