import {MValueLabel} from "./MValueLabel";

import React, {useState, useEffect} from 'react';
import {MConvert} from "../../utilities/MConvert";

interface ITimeDifferenceLabelProps {
    dateString: string;
    prefixText?: string;
    dateLabels: any;
}

export function TimeDifferenceLabel({dateString, prefixText, dateLabels}: ITimeDifferenceLabelProps) {
    const [timeDifference, setTimeDifference] = useState(MConvert.toTimeDifference(dateString, prefixText, dateLabels));

    useEffect(() => {
        const interval = setInterval(() => {
            setTimeDifference(MConvert.toTimeDifference(dateString, prefixText, dateLabels));
        }, 750);

        return () => clearInterval(interval);
    }, [dateString, dateLabels]);

    return <MValueLabel value={timeDifference}/>;
};