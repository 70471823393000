import {HTMLAttributes, useEffect, useState} from "react";
import "./MCookiePanel.css";

import {MButton} from "./MButton";
import {MButtonBar} from "./MButtonBar";
import {MLink} from "./MLink";
import {MHint} from "./MHint";

import {IMInteractiveComponentProps} from "./IMInteractiveComponentProps";
import {MStatusStyle} from "./MStatusStyle";

interface IMCookiePanelProps extends IMInteractiveComponentProps<HTMLElement>, HTMLAttributes<HTMLElement> {
    /**
     * Anzeige des Panels unabhängig von den Cookieeinstellungen anzeigen
     */
    forceDisplay?: boolean;
    /**
     * Als Overlay Panel rendern. False rendert die Komponente normal als Block
     */
    renderAsOverlay?: boolean;
    /**
     * Statushinweise rendern
     */
    renderCookiesStatusHints?: boolean;
    /**
     * Wird aufgerufen, wenn sich der Einstellungsstatus durch Akzeptieren oder Ablehnen geändert hat.
     * @param {boolean} value True = Akzeptiert, False = Abgelehnt
     */
    settingsSet?: (value: boolean) => void;
}

export function MCookiePanel({
                                 forceDisplay,
                                 renderAsOverlay,
                                 settingsSet,
                                 renderCookiesStatusHints,
                                 ...props
                             }: IMCookiePanelProps) {
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [cookiesAccepted, setCookiesAccepted] = useState<boolean | null>(null);

    if (renderAsOverlay == null) {
        renderAsOverlay = true;
    }

    useEffect(() => {
        const consentGivenStr = localStorage.getItem('cookieConsent');

        if (consentGivenStr != null) {
            setCookiesAccepted(consentGivenStr == "true");
        } else {
            setCookiesAccepted(null);
        }

        if (!consentGivenStr || forceDisplay) {
            setIsVisible(true);
        }
    }, []);

    const handleAccept = () => {
        selectionDone(true);
    };

    const handleReject = () => {
        selectionDone(false);
    };

    function selectionDone(accepted: boolean) {
        localStorage.setItem('cookieConsent', accepted.toString());

        if (!forceDisplay) {
            setIsVisible(false);
        }

        setCookiesAccepted(accepted);

        if (settingsSet != null) {
            settingsSet(accepted);
        }
    }

    function renderSettingsHint() {
        if (cookiesAccepted == null) {
            return <MHint title={"Bislang hast du dich noch nicht für deine Cookie Einstellungen entschieden!"}
                          description={"Wenn Du den Cookie-Einstellungen nicht zustimmst, kann dies zu Einschränkungen der Funktionalität dieser Website führen."}
                          statusStyle={MStatusStyle.Warning}
                          style={{marginTop: "12px"}}/>
        } else if (!cookiesAccepted) {
            return <MHint title={"Aktuell hast du alle Cookies abgelehnt!"}
                          description={"Wenn Du den Cookie-Einstellungen nicht zustimmst, kann dies zu Einschränkungen der Funktionalität dieser Website führen."}
                          statusStyle={MStatusStyle.Default}
                          style={{marginTop: "12px"}}/>
        } else if (cookiesAccepted) {
            return <MHint title={"Aktuell hast du alle Cookies akzeptiert!"}
                          description={"Du kannst die Website ohne Einschränkungen der Funktionalität nutzen."}
                          statusStyle={MStatusStyle.Nice}
                          style={{marginTop: "12px"}}/>
        }
    }

    if (!isVisible) {
        return <></>;
    }

    return <div className={renderAsOverlay ? "m-cookie-panel overlay" : "m-cookie-panel"} {...props}>
        <h1 style={{marginBottom: "6px"}}>Cookies</h1>
        <p>
            Diese Website verwendet Cookies, um die Nutzererfahrung zu verbessern. Durch die Nutzung unserer Website
            stimmen Sie der Verwendung von Cookies zu.<br/>
            <MLink href={"/privacy-policy"} style={{marginTop: "6px", fontSize: "14px", fontWeight: 600}}>Weitere
                Informationen</MLink>
        </p>
        <MButtonBar style={{marginTop: "10px"}}>
            <MButton onClick={handleAccept}
                     disabled={cookiesAccepted == true}
                     style={cookiesAccepted == true ? {opacity: 0.5} : {}}>Akzeptieren</MButton>
            <MButton onClick={handleReject}
                     disabled={cookiesAccepted == false}
                     style={cookiesAccepted == false ? {opacity: 0.5} : {}}>Ablehnen</MButton>
        </MButtonBar>
        {renderCookiesStatusHints && renderSettingsHint()}
    </div>;
}