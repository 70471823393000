export enum MUnit {
    /**
     * Millimeter
     */
    Millimeter = 10,
    /**
     * Meter
     */
    Meter = 11,
    /**
     * Millisekunde
     */
    Millisecond = 20,
    /**
     * Sekunde
     */
    Second = 21,
    /**
     * Minute
     */
    Minute = 22,
    /**
     * Stunde
     */
    Hour = 23,
    /**
     * Tag
     */
    Day = 24,
    /**
     * Woche
     */
    Week = 25,
    /**
     * Monat
     */
    Month = 26,
    /**
     * Jahr
     */
    Year = 27,
}

export const MUnitSymbols: { [key in MUnit]: string } = {
    [MUnit.Millimeter]: "mm",
    [MUnit.Meter]: "m",
    [MUnit.Millisecond]: "ms",
    [MUnit.Second]: "s",
    [MUnit.Minute]: "min",
    [MUnit.Hour]: "h",
    [MUnit.Day]: "d",
    [MUnit.Week]: "wk",
    [MUnit.Month]: "mo",
    [MUnit.Year]: "yr",
};