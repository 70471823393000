import "./MValueLabel.css";

import {IMInteractiveComponentProps} from "./IMInteractiveComponentProps";
import {HTMLAttributes, useEffect, useState} from "react";
import {MUnit, MUnitSymbols} from "../../utilities/MUnit";

interface IMValueLabelProps extends IMInteractiveComponentProps<HTMLElement>, HTMLAttributes<HTMLElement> {
    value?: string | number;
    unit?: MUnit;
    precision?: number;
}

export function MValueLabel({value, unit, precision, ...props}: IMValueLabelProps) {
    const [calculatedValue, setCalculatedValue] = useState<string>();

    useEffect(() => {
        let convertedValue = convertValue(value);

        if (unit != null) {
            convertedValue += " " + MUnitSymbols[unit];
        }

        setCalculatedValue(convertedValue);
    }, [value]);

    function convertValue(value: any) {
        if (value == null) {
            return "";
        }

        if (typeof value !== "number") {
            return value;
        }

        if (precision == null) {
            if (Number.isSafeInteger(value)) {
                return value.toString();
            } else {
                return value.toFixed(1).toString();
            }
        } else {
            return value.toFixed(precision).toString();
        }
    }

    return <span className="m-value-label" {...props}>
        {calculatedValue}
    </span>;
}